import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Container from "components/shared/container";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <h1>{`About`}</h1>
      <h3>{`Who?`}</h3>
      <p>{`This site is currently a single-person project, run by myself, `}<a parentName="p" {...{
          "href": "https://www.dandenney.com"
        }}>{`Dan Denney`}</a>{`. I've been fascinated by true crime since I was young, but it was attending CrimeCon in 2017 that inspired me to start taking action. Since my background is web development, I thought that this was the best way to start.`}</p>
      <h3>{`Why?`}</h3>
      <p>{`This goal for this project is to help inspire solutions for a few problems that I've run into while researching cases: information is often spread out amongst pages of forums, and we rarely have direct access to databases. I'm not sure that this is the answer, but I'm hoping that it can make information easier to find and to inspire others to create better solutions.`}</p>
      <h3>{`What`}</h3>
      <p>{`I belive that it is vital for the information around cases to be publicly accessible, so anything in this project is. The code that runs the site is `}<a parentName="p" {...{
          "href": "https://github.com/dandenney/true-crime-researchers"
        }}>{`open-source on GitHub`}</a>{` and the database is `}<a parentName="p" {...{
          "href": "https://airtable.com/shrByS81gAOehnvC4"
        }}>{`publicly available on Airtable`}</a>{`. Due to the pricing plan, collaborators on the Airtable database are restricted, but anyone can copy the entire database at any time.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      